import React from "react";
import axios from "axios";

import firebase from "firebase/app";

import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const FirebaseContext = React.createContext();

const FirebaseProvider = ({ children }) => {
  const [firebaseConfig, setFirebaseConfig] = React.useState();

  React.useEffect(() => {
    if (["development", "test"].includes(process.env.NODE_ENV)) {
      setFirebaseConfig({
        apiKey: "AIzaSyBSE8-wSAXfx5SZGc1chcvoWiahJ2Y5-JQ",
        authDomain: "tecnogis-sigweb.firebaseapp.com",
        projectId: "tecnogis-sigweb",
        storageBucket: "tecnogis-sigweb.appspot.com",
        messagingSenderId: "362737529826",
        appId: "1:362737529826:web:2db9d35e71400d84acffbb",
        measurementId: "G-HWDH0FG8BS"
      });
  
      return;
    }

    axios
      .get("/__/firebase/init.json")
      .then((res) => setFirebaseConfig(res.data))
      .catch((err) => console.log(err));
  }, []);

  let firebaseApp, firebaseAuth, db, storage;

  if (firebaseConfig) {
    firebaseApp = firebase.initializeApp(firebaseConfig);
    firebaseAuth = firebaseApp.auth();
    db = firebase.firestore(firebaseApp);
    storage = firebase.storage(firebaseApp);
  }

  const value = {
    firebaseConfig,
    firestore: firebase.firestore,
    firebaseApp,
    firebaseAuth,
    db,
    storage,
  };

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseContext;

export { FirebaseProvider };
