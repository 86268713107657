import React from "react";
import { Divider, Button, Table, Space, Typography, Tag } from "antd";
import BaseLayout from "../BaseLayout";
import UserForm from "./Form";
import AuthenticationContext from "../../contexts/authentication";
import FirebaseContext from "../../contexts/firebase";
import axios from "axios";

const getColumns = ({ authenticationContext, firebaseContext, loadUsers }) => {
  const { firebaseAuth } = firebaseContext;

  return [
    {
      key: "displayName",
      title: "Nome",
      dataIndex: "displayName",
    },
    {
      key: "email",
      title: "E-mail",
      dataIndex: "email",
      responsive: ["lg"],
    },
    {
      key: "tags",
      responsive: ["lg"],
      render: (text, record) => (
        <>
          <Tag color={record.disabled ? "red" : "blue"}>
            {record.disabled ? "Desabilitado" : "Habilitado"}
          </Tag>
        </>
      ),
    },
    {
      key: "action",
      title: "",
      render: (text, record) => {
        const handleDisableUser = async () => {
          const token = await firebaseAuth.currentUser.getIdToken();

          const config = {
            headers: {
              Authorization: "Bearer " + token,
            },
          };

          axios
            .patch(
              `${process.env.REACT_APP_API_URL}/api/users/disable/${record.uid}`,
              {},
              config
            )
            .then(() => {
              loadUsers();
            })
            .catch((error) => console.log("error", error));
        };

        const handleEnableUser = async () => {
          const token = await firebaseAuth.currentUser.getIdToken();

          const config = {
            headers: {
              Authorization: "Bearer " + token,
            },
          };

          axios
            .patch(
              `${process.env.REACT_APP_API_URL}/api/users/enable/${record.uid}`,
              {},
              config
            )
            .then(() => {
              loadUsers();
            })
            .catch((error) => console.log("error", error));
        };

        return (
          <Space size="middle">
            {authenticationContext.checkPermission("users.edit") && (
              <UserForm
                data={record}
                callback={loadUsers}
                button={
                  <Button type="link" size="small">
                    Editar
                  </Button>
                }
              />
            )}
            {authenticationContext.checkPermission("users.disable") && (
              <>
                {record.disabled ? (
                  <Button type="link" size="small" onClick={handleEnableUser}>
                    Ativar
                  </Button>
                ) : (
                  <Button type="link" size="small" onClick={handleDisableUser}>
                    Desativar
                  </Button>
                )}
              </>
            )}
          </Space>
        );
      },
    },
  ];
};

export default function UsersList() {
  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const isMountedRef = React.useRef(null);

  const authenticationContext = React.useContext(AuthenticationContext);
  const firebaseContext = React.useContext(FirebaseContext);

  const { firebaseAuth } = firebaseContext;

  const loadUsers = React.useCallback(async () => {
    isMountedRef.current = true;

    setLoading(true);

    const token = await firebaseAuth.currentUser.getIdToken();

    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}/api/users`, config)
      .then((res) => {
        if (isMountedRef.current)
          setUsers(res.data.map((d) => ({ key: d.uid, ...d })));
        setLoading(false);
      })
      .catch(() => setLoading(false));

    return () => (isMountedRef.current = false);
  }, [firebaseAuth]);

  React.useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  return (
    <BaseLayout>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography.Title level={3} style={{ marginBottom: 0 }}>
          Usuários
        </Typography.Title>
        {authenticationContext.checkPermission("users.create") && (
          <UserForm
            button={<Button type="primary">Criar usuário</Button>}
            callback={loadUsers}
          />
        )}
      </div>
      <Divider style={{ margin: "16px 0" }} />
      <Table
        columns={getColumns({ authenticationContext, firebaseContext, loadUsers })}
        dataSource={users}
        loading={loading}
      />
    </BaseLayout>
  );
}
