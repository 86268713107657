import React from 'react'
import styled from 'styled-components'

import { Collapse, Checkbox, Slider } from 'antd';

const { Panel } = Collapse;

const Box = styled.div`
  width: 16rem;
  z-index: 999;
  max-height: 80%;
  overflow-y: scroll;
  border: 1px solid #d9d9d9;
`

const Layer = ({ map, layer }) => {
  const [visible, setVisible] = React.useState(layer.visible)
  const [opacity, setOpacity] = React.useState(100)

  const handleLayerVisible = (layerName) => {
    map.getLayers().forEach(l => {
      if (l.get('name') === layerName) {
        l.setVisible(!visible)
        setVisible(!visible)
      }
    })
  }

  const handleLayerOpacity = (layerName, value) => {
    map.getLayers().forEach(l => {
      if (l.get('name') === layerName) {
        l.setOpacity(parseFloat(value / 100))
        setOpacity(value)
      }
    })
  }

  return (
    <div>
      <Checkbox
        checked={visible}
        onChange={() => handleLayerVisible(layer.name)}
      >
        {layer.name}
      </Checkbox>
      <Slider
        defaultValue={30}
        value={opacity}
        onChange={(value) => handleLayerOpacity(layer.name , value)}
      />
    </div>
  )
}

const Layers = ({ map, groups }) => {
  return (
    <Box>
      <Collapse defaultActiveKey={['1']} onChange={() => {}}>
        {Object.entries(groups).map(e => (
          <Panel header={e[0]} key={e[0]}>
            {e[1].map((layer) => (
              <Layer
                map={map}
                layer={layer}
                key={layer.name}
              />
            ))}            
          </Panel>
        ))}
      </Collapse>
    </Box>
  )
}

export default Layers