import React from "react";
import {
  Button,
  Form,
  Input,
  Space,
  Switch,
} from "antd";
import MetadataDialog from "./MetadataDialog";

function LayerItem({ field, remove }) {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <div style={{ display: 'flex', marginBottom: open ? 12 : 0, alignItems: 'center' }}>
        <Form.Item
          name={[field.name, "name"]}
          fieldKey={[field.fieldKey, "name"]}
          style={{ marginRight: 8, marginBottom: 0, flex: 1 }}
          rules={[
            {
              required: true,
              message: "Nome é obrigatório!",
            },
          ]}
        >
          <Input placeholder="Nome" />
        </Form.Item>
        <Button
          onClick={() => setOpen(!open)}
          type="default"
          size="small"
          style={{ marginRight: 8 }}
        >
          Abrir
        </Button>
        <Button
          onClick={() => remove(field.name)}
          type="default"
          size="small"
          danger
        >
          Remover
        </Button>
      </div>
      <div style={{ display: open ? 'block' : 'none' }}>
        <Form.Item
          name={[field.name, "layers"]}
          fieldKey={[field.fieldKey, "layers"]}
          style={{ marginBottom: 12 }}
          rules={[
            {
              required: true,
              message: "URL é obrigatório!",
            },
          ]}
        >
          <Input placeholder="Camada" />
        </Form.Item>
        <Form.Item
          name={[field.name, "group"]}
          fieldKey={[field.fieldKey, "group"]}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "URL é obrigatório!",
            },
          ]}
        >
          <Input placeholder="Grupo" />
        </Form.Item>
      </div>
      <div>
        <Space align="baseline">
          <div style={{ display: open ? 'block' : 'none' }}>
            <MetadataDialog field={field} />
          </div>
          <Form.Item
            name={[field.name, "tiled"]}
            fieldKey={[field.fieldKey, "tiled"]}
            valuePropName="checked"
            style={{ marginBottom: 0 }}
          >
            <Switch
              size="small"
              checkedChildren="Tiled"
              unCheckedChildren="Tiled"
              defaultChecked
            />
          </Form.Item>
          <Form.Item
            name={[field.name, "visible"]}
            fieldKey={[field.fieldKey, "visible"]}
            valuePropName="checked"
            style={{ marginBottom: 0 }}
          >
            <Switch
              size="small"
              checkedChildren="Visível"
              unCheckedChildren="Visível"
              defaultChecked
            />
          </Form.Item>
          <Form.Item
            name={[field.name, "search"]}
            fieldKey={[field.fieldKey, "search"]}
            valuePropName="checked"
            style={{ marginBottom: 0 }}
          >
            <Switch
              size="small"
              checkedChildren="Consulta"
              unCheckedChildren="Consulta"
            />
          </Form.Item>
        </Space>
      </div>
    </>
  )
}

export default LayerItem