import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import FirebaseContext, { FirebaseProvider, } from "./contexts/firebase";
import AuthenticationContext, { AuthenticationProvider, } from "./contexts/authentication";
import SettingsContext, { SettingsProvider } from "./contexts/settings";
import Favicon from "react-favicon";

import Login from "./components/Login";
import MapsList from "./components/Map/List";
import MapShow from "./components/Map/Show";
import UserList from "./components/User/List";
import UserEdit from "./components/User/Edit";
import Embed from "./components/Embed";
import SharedViews from "./pages/SharedViews";
import Loading from "./components/Loading";

function WithFirebase({ children }) {
  const firebaseContext = React.useContext(FirebaseContext);

  if (firebaseContext.firebaseConfig === undefined) return <Loading />;

  return children;
}

function WithSettings({ children }) {
  const settingsContext = React.useContext(SettingsContext);

  if (settingsContext.data === null) return <Loading />;

  return (
    <>
      <Favicon url={settingsContext.data.faviconUrl} />
      {children}
    </>
  );
}

function WithAuthentication({ children }) {
  const authenticationContext = React.useContext(AuthenticationContext);

  if (!authenticationContext.ready) return <Loading />;  

  return children;
}

function PrivateRoute({ component: Component, ...rest }) {
  const authenticationContext = React.useContext(AuthenticationContext);

  if (!authenticationContext.isAuthenticated) return (
    <Redirect to={{ pathname: "/login" }} />
  );

  if (authenticationContext.maps === null && authenticationContext.permissions === null) {
    return <Loading />;
  }

  return (
    <Route render={(props) => <Component {...props} />} {...rest} />
  );
};

export default function App() {
  return (
    <FirebaseProvider>
      <WithFirebase>
        <SettingsProvider>
          <AuthenticationProvider>
            <WithAuthentication>
              <WithSettings>
                <Router>
                  <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/map/:permalink" component={MapShow} />
                    <Route path="/embed/:permalink/:geolink/:width/:height" component={Embed} />
                    <Route path="/shared-views" component={SharedViews} />
                    <PrivateRoute path="/users" component={UserList} />
                    <PrivateRoute path="/account" component={UserEdit} />
                    <PrivateRoute path="/" component={MapsList} />
                  </Switch>
                </Router>
              </WithSettings>
            </WithAuthentication>
          </AuthenticationProvider>
        </SettingsProvider>
      </WithFirebase>
    </FirebaseProvider>
  );
};
