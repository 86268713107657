import React from "react";
import OlMap from "ol/Map";
import { createXYZ } from "ol/tilegrid";
import OlLayerVectorTile from "ol/layer/VectorTile";
import OlSourceVectorTile from "ol/source/VectorTile";
import OlFormatMVT from "ol/format/MVT";
import OlView from "ol/View";
import { fromLonLat } from "ol/proj";

const SharedViews = () => {
  const [olMap, setMap] = React.useState();
  const [mapId] = React.useState(`map-${Math.random()}`);

  const initializeMap = React.useCallback(() => {
    setMap(
      new OlMap({
        layers: [new OlLayerVectorTile({
          source: new OlSourceVectorTile({
            tilePixelRatio: 1,
            tileGrid: createXYZ({ maxZoom: 19 }),
            format: new OlFormatMVT(),
            // url: 'https://geoserver.tecnogis.com.br/geoserver/gwc/service/tms/1.0.0/public%3Aimagem_demonstracao_alta_resolucao@EPSG%3A4326@png/{z}/{x}/{-y}.png'
            url: 'https://geoserver.tecnogis.com.br/geoserver/gwc/service/tms/1.0.0/public%3Aimagem_demonstracao_alta_resolucao@EPSG%3A4326@pbf/{z}/{x}/{-y}.pbf'
          })
        })],
        view: new OlView({
          center: fromLonLat([-36.284858, -9.806275]),
          zoom: 19,
        }),
        // view: new OlView({
        //   center: [0, 0],
        //   zoom: 2
        // }),
        controls: [],
      })
    );
  }, []);

  React.useEffect(() => {
    initializeMap();
  }, [initializeMap]);

  React.useEffect(() => {
    if (olMap) olMap.setTarget(mapId);
  }, [olMap, mapId]);

  return (
    <div
      id={mapId}
      style={{ position: "fixed", width: "100%", height: "100%" }}
    />
  )
}

export default SharedViews;