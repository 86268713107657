import React from "react";
import { Spin } from "antd";

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
  },
};

export default function Loading() {
  return (
    <div style={styles.container}>
      <Spin size="large" />
    </div>
  );
}
