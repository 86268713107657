/* eslint-disable no-template-curly-in-string */
import React from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Card,
  Collapse,
  Upload,
  Checkbox,
  message,
} from "antd";
import {
  PlusOutlined,
  UploadOutlined,
  MinusCircleFilled,
} from "@ant-design/icons";
import AuthenticationContext from "../../contexts/authentication";
import FirebaseContext from "../../contexts/firebase";

import LayerItem from './LayerItem'

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const validateMessages = {
  required: "'${label}' é obrigatório!",
};

const initialValues = {
  title: "",
  permalink: "",
  description: "",
  lat: "",
  lon: "",
  zoom: "",
  layers: [],
  search: [],
  isDeleted: false,
  showOpenlayers: false,
};

function stringToSlug(str) {
  str = str.replace(/^\s+|\s+$/g, "");
  str = str.toLowerCase();

  var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaaeeeeiiiioooouuuunc------";

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");

  return str;
}

export default function MapDialog({
  data,
  button,
  disabled,
  callback = () => {},
}) {
  const [form] = Form.useForm();
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [imageAsFile, setImageAsFile] = React.useState(null)

  const authenticationContext = React.useContext(AuthenticationContext);
  const firebaseContext = React.useContext(FirebaseContext);

  const { db, storage } = firebaseContext;

  React.useEffect(() => {
    if (data && visible) form.setFieldsValue(data);
  }, [data, visible, form]);

  const onFinish = async (values) => {
    setLoading(true);

    if (values.layers.length === 0) {
      setLoading(false);
      return message.error("Obrigatório adicionar as camadas.");
    }

    if (imageAsFile) {
      const storageRef = storage.ref();
      if (data && data.image) {
        const deleteRef = storageRef.child(data.image);
        deleteRef
          .delete()
          .catch(() => {
            console.log("error when delete the image");
          });
      }
      await storageRef
        .child(`maps/${imageAsFile.name}`)
        .put(imageAsFile)
        .catch(() => {
          message.error("Erro ao fazer o upload da imagem.");
        });
    }

    if (!values.isDeleted) values.isDeleted = false;
    if (!values.createdAt) values.createdAt = new Date();
    delete values.image;
    delete values.upload;

    const layers = values.layers.map(layer => ({
      ...layer,
      search: layer.search || false,
      metadata: layer.metadata || [],
    }));

    const payload = {
      ...values,
      layers,
      permalink: stringToSlug(values.title),
      lat: parseFloat(values.lat),
      lon: parseFloat(values.lon),
      zoom: parseInt(values.zoom),
    };

    if (imageAsFile) {
      const image = `maps/${imageAsFile.name}`
      payload.image = image;
    }

    if (data) {
      db.collection("maps")
        .doc(data.id)
        .set(payload, { merge: true })
        .then(() => {
          setLoading(false);
          setVisible(false);
          message.success("Atualizado com sucesso!");
          authenticationContext.refresh();
          callback();
        });
    } else {
      const mapRef = db.collection("maps").doc();
      await mapRef
        .set(payload)
        .then(() => {
          setLoading(false);
          setVisible(false);
          message.success("Criado com sucesso!");
          authenticationContext.refresh();
          callback();
        });
      db.collection("users")
        .doc(authenticationContext.user.uid)
        .set({ maps: [...authenticationContext.maps, mapRef.id] }, { merge: true });
    }
  };

  const handleOk = () => setVisible(false);

  const handleCancel = () => setVisible(false);

  const normFile = (e) => {
    if (Array.isArray(e)) return e;
    return e && e.fileList;
  };

  const handleImage = (file) => {
    if (file) setImageAsFile(file);
    return false;
  };

  return (
    <>
      {React.cloneElement(button, {
        onClick: () => {
          if (!disabled) setVisible(true);
        },
      })}
      {visible && (
        <Modal
          title={`${data ? "Editar" : "Criar"} mapa`}
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          width={600}
          forceRender
        >
          <Form
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            validateMessages={validateMessages}
            initialValues={initialValues}
          >
            <Form.Item
              {...layout}
              name="title"
              label="Título"
              rules={[{ required: true }]}
            >
              <Input disabled={loading} />
            </Form.Item>
            <Form.Item
              {...layout}
              name="description"
              label="Descrição"
              extra="A descrição deve conter até 50 caracteres."
            >
              <Input.TextArea disabled={loading} maxLength={50} />
            </Form.Item>
            <Form.Item
              {...layout}
              name="lat"
              label="Latitude"
              rules={[{ required: true }]}
              extra="Exemplo: -9.99999"
            >
              <Input disabled={loading} />
            </Form.Item>
            <Form.Item
              {...layout}
              name="lon"
              label="Longitude"
              rules={[{ required: true }]}
              extra="Exemplo: -35.99999"
            >
              <Input disabled={loading} />
            </Form.Item>
            <Form.Item
              {...layout}
              name="zoom"
              label="Zoom"
              rules={[{ required: true }]}
            >
              <Input disabled={loading} />
            </Form.Item>
            <Form.Item
              {...layout}
              name="upload"
              label="Imagem"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                name="logo"
                action="/upload.do"
                listType="picture"
                beforeUpload={handleImage}
              >
                <Button icon={<UploadOutlined />}>
                  Clique para selecionar a imagem
                </Button>
              </Upload>
            </Form.Item>
            <Form.Item
              {...layout}
              name="showOpenlayers"
              label=" "
              valuePropName="checked"
            >
              <Checkbox>Usar camada Openlayers</Checkbox>
            </Form.Item>
            <Form.Item>
              <Collapse>
                <Collapse.Panel header="Busca" key="1" forceRender>
                  <Form.List name="search">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field) => (
                          <Card
                            key={`item-${Math.random()}`}
                            style={{ marginBottom: 24 }}
                          >
                            <Form.Item
                              name={[field.name, "label"]}
                              fieldKey={[field.fieldKey, "label"]}
                              style={{ marginBottom: 24 }}
                              rules={[
                                {
                                  required: true,
                                  message: "Nome é obrigatório!",
                                },
                              ]}
                            >
                              <Input placeholder="Label" />
                            </Form.Item>
                            <Form.Item
                              name={[field.name, "field"]}
                              fieldKey={[field.fieldKey, "field"]}
                              style={{ marginBottom: 24 }}
                              rules={[
                                {
                                  required: true,
                                  message: "Nome é obrigatório!",
                                },
                              ]}
                            >
                              <Input placeholder="Campo" />
                            </Form.Item>
                            <Form.Item
                              name={[field.name, "layer"]}
                              fieldKey={[field.fieldKey, "layer"]}
                              style={{ marginBottom: 24 }}
                              rules={[
                                {
                                  required: true,
                                  message: "Nome é obrigatório!",
                                },
                              ]}
                            >
                              <Input placeholder="Camada" />
                            </Form.Item>
                            <MinusCircleFilled
                              onClick={() => remove(field.name)}
                              style={{
                                position: 'absolute',
                                top: -5,
                                right: -7,
                                backgroundColor: 'white'
                              }}
                            />
                          </Card>
                        ))}
                        <Form.Item style={{ marginBottom: 0 }}>
                          <Button
                            type="dashed"
                            onClick={() =>
                              add({
                                label: "",
                                field: "",
                                layer: "",
                              })
                            }
                            block
                            icon={<PlusOutlined />}
                          >
                            Adicionar
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Collapse.Panel>
              </Collapse>
            </Form.Item>
            <Form.Item>
              <Collapse>
                <Collapse.Panel header="Camadas" key="1" forceRender>
                  <Form.List name="layers">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field) => (
                          <Card
                            key={`item-${Math.random()}`}
                            style={{ marginBottom: 24 }}
                          >
                            <LayerItem field={field} remove={remove} />
                          </Card>
                        ))}
                        <Form.Item style={{ marginBottom: 0 }}>
                          <Button
                            type="dashed"
                            onClick={() =>
                              add({
                                name: "",
                                layers: "",
                                tiled: true,
                                visible: true,
                              })
                            }
                            block
                            icon={<PlusOutlined />}
                          >
                            Adicionar camada
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Collapse.Panel>
              </Collapse>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Enviar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
}
