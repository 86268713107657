import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Layout, Menu } from "antd";
import { Helmet } from "react-helmet";
import AuthenticationContext from "../contexts/authentication";
import SettingsContext from "../contexts/settings";
import FirebaseContext from "../contexts/firebase";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export default function BaseLayout({ children }) {
  const history = useHistory();

  const authenticationContext = React.useContext(AuthenticationContext);
  const settingsContext = React.useContext(SettingsContext);
  const firebaseContext = React.useContext(FirebaseContext);

  const { firebaseAuth } = firebaseContext;

  return (
    <>
      <Helmet>
        <title>{settingsContext.data.title}</title>
      </Helmet>
      <Layout style={{ minHeight: "100vh" }}>
        <Layout.Sider
          breakpoint="lg"
          collapsedWidth="0"
          theme={settingsContext.data.theme || "dark"}
          zeroWidthTriggerStyle={{ top: 12 }}
        >
          <Logo>
            <img
              src={settingsContext.data.logoApp}
              alt={settingsContext.data.title}
            />
          </Logo>
          <Menu
            mode="inline"
            theme={settingsContext.data.theme || "dark"}
          >
            {authenticationContext.checkPermission("maps.list") && (
              <Menu.Item key="1" onClick={() => history.push("/maps")}>
                Mapas
              </Menu.Item>
            )}
            {authenticationContext.checkPermission("users.list") && (
              <Menu.Item key="2" onClick={() => history.push("/users")}>
                Usuários
              </Menu.Item>
            )}
            <Menu.Item key="3" onClick={() => history.push("/account")}>
              Conta
            </Menu.Item>
            <Menu.Item key="4" onClick={() => firebaseAuth.signOut()}>
              Sair
            </Menu.Item>
          </Menu>
        </Layout.Sider>
        <Layout>
          {isMobile && <div style={{ height: 55 }}></div>}
          <Layout.Content style={{ padding: 16 }}>
            {children}
          </Layout.Content>
          <Footer>
            Desenvolvido por TecnoGIS - Soluções em Geotecnologias LTDA. CNPJ: 41.397.643/0001-06
          </Footer>
        </Layout>
      </Layout>
    </>
  );
}

const Logo = styled.div`
  width: 98px;
  height: 140px;
  margin: 0 auto;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const Footer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #f5f5f5;
  padding: 4px;
  margin-left: 10px;
`;
