import React from "react";
import { GeoJSON, WFS } from "ol/format";
import { equalTo as equalToFilter } from "ol/format/filter";
import { fromLonLat } from "ol/proj";
import { useParams } from "react-router-dom";
import FirebaseContext from "../../contexts/firebase";
import SettingsContext from "../../contexts/settings";
import OlMap from "ol/Map";
import OlView from "ol/View";
import OlLayerTile from "ol/layer/Tile";
import OlLayerImage from "ol/layer/Image";
import OlTileWMS from "ol/source/TileWMS";
import OlImageWMS from "ol/source/ImageWMS";

export default function Embed() {
  let { permalink, geolink, width, height } = useParams();
  const [map, setMap] = React.useState();
  const [mapId] = React.useState(`map-${Math.random()}`);

  const firebaseContext = React.useContext(FirebaseContext);
  const settingsContext = React.useContext(SettingsContext);

  const { db } = firebaseContext;

  const getLayer = React.useCallback((l) => {
    if (l.tiled) {
      return new OlLayerTile({
        title: l.name,
        name: l.name,
        visible: l.visible,
        source: new OlTileWMS({
          ratio: 1,
          url: `${settingsContext.data.geoserverUrl}/public/wfs`,
          serverType: "geoserver",
          crossOrigin: "anonymous",
          params: {
            LAYERS: l.layers,
            VERSION: "1.1.1",
            TILED: true,
            exceptions: "application/vnd.ogc.se_inimage",
          },
        }),
      });
    } else {
      return new OlLayerImage({
        title: l.name,
        name: l.name,
        visible: l.visible,
        source: new OlImageWMS({
          ratio: 1,
          url: `${settingsContext.data.geoserverUrl}/public/wfs`,
          serverType: "geoserver",
          crossOrigin: "anonymous",
          params: {
            LAYERS: l.layers,
            VERSION: "1.1.1",
            exceptions: "application/vnd.ogc.se_inimage",
          },
        }),
      })
    }
  }, [settingsContext.data.geoserverUrl]);

  const initializeMap = React.useCallback((data) => {
    const { layers, lat, lon, zoom } = data;

    const tiles = layers.map((l) => getLayer(l));

    setMap(
      new OlMap({
        layers: tiles,
        view: new OlView({
          center: fromLonLat([lon, lat]),
          zoom,
        }),
        controls: [],
      })
    );
  }, [getLayer]);

  const fetchData = React.useCallback((map) => {
    const featureRequest = new WFS().writeGetFeature({
      srsName: "EPSG:3857",
      featureTypes: ["public:lotes"],
      outputFormat: "application/json",
      filter: equalToFilter("geolink", geolink),
    });

    fetch(`${settingsContext.data.geoserverUrl}/wfs`, {
      method: "POST",
      body: new XMLSerializer().serializeToString(featureRequest),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        var features = new GeoJSON().readFeatures(json);
        map
          .getView()
          .fit(features[0].getGeometry().getExtent(), map.getSize());
      });
  }, [geolink, settingsContext.data.geoserverUrl]);

  React.useEffect(() => {
    if (map) {
      map.setTarget(mapId);
      fetchData(map);
    }
  }, [fetchData, map, mapId]);

  React.useEffect(() => {
    db.collection("maps")
      .where("permalink", "==", permalink)
      .get()
      .then((qs) => {
        if (!qs.empty) initializeMap(qs.docs[0].data());
      });
  }, [db, permalink, initializeMap]);

  return (
    <div
      id={mapId}
      style={{ width: parseInt(width), height: parseInt(height) }}
    />
  );
}