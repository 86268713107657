/* eslint-disable no-template-curly-in-string */
import React from "react";
import { Button, Form, Input, Typography, Layout, Divider, message } from "antd";
import BaseLayout from "../BaseLayout";
import AuthenticationContext from "../../contexts/authentication";
import FirebaseContext from "../../contexts/firebase";
import axios from "axios";

const validateMessages = {
  required: "'${label}' é obrigatório!",
};

const initialValues = {
  displayName: "",
  email: "",
  password: "",
  passwordConfirm: "",
};

export default function UsersList() {
  const [form] = Form.useForm();

  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const authenticationContext = React.useContext(AuthenticationContext);
  const firebaseContext = React.useContext(FirebaseContext);

  const { firebaseAuth } = firebaseContext;

  const onFinish = async (values) => {
    setLoading(true);

    const token = await firebaseAuth.currentUser.getIdToken();

    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    const { displayName, email, password } = values;

    const payload = {
      displayName,
      email,
      password,
    };

      axios
        .patch(`${process.env.REACT_APP_API_URL}/api/users/${user.uid}`, payload, config)
        .then((res) => {
          setLoading(false);
          form.setFieldsValue({ ...res });
          message.success("Conta atualizada com sucesso.");
        });
  };

  React.useEffect(() => {
    const { user } = authenticationContext;
    if (user) {
      setUser({ ...user });
      form.setFieldsValue({ ...user });
    }
  }, [form, authenticationContext]);

  return (
    <BaseLayout>
      <Typography.Title level={3}>Editar conta</Typography.Title>
      <Divider style={{ margin: "16px 0" }} />
      <Layout.Content style={{ background: "#ffffff", padding: 24 }}>
        <Form
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          validateMessages={validateMessages}
          initialValues={initialValues}
          layout="vertical"
        >
          <Form.Item
            name="displayName"
            label="Nome"
            rules={[{ required: true }]}
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "O e-mail não é válido!",
              },
              {
                required: true,
              },
            ]}
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item name="password" label="Senha" hasFeedback>
            <Input.Password autoComplete="new-password" disabled={loading} />
          </Form.Item>
          <Form.Item
            name="passwordConfirm"
            label="Confirmar senha"
            dependencies={["password"]}
            hasFeedback
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "As duas senhas que você digitou não correspondem!"
                  );
                },
              }),
            ]}
          >
            <Input.Password disabled={loading} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Enviar
            </Button>
          </Form.Item>
        </Form>
      </Layout.Content>
    </BaseLayout>
  );
}
