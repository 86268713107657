import React from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  Spin,
  Divider,
  Button,
  Popconfirm,
  Typography,
  Avatar,
} from "antd";
import { EyeOutlined, EditOutlined, DeleteOutlined, CodeOutlined } from "@ant-design/icons";
import BaseLayout from "../BaseLayout";
import MapForm from "./Form";
import Embed from "./Embed";
import AuthenticationContext from "../../contexts/authentication";
import FirebaseContext from "../../contexts/firebase";
import _ from "lodash";

const MapAvatar = ({ data }) => {
  const [image, setImage] = React.useState(null);
  const isMountedRef = React.useRef(null);

  const firebaseContext = React.useContext(FirebaseContext);

  const { storage } = firebaseContext;

  React.useEffect(() => {
    isMountedRef.current = true;
    if (data.image) {
      const storageRef = storage.ref();
      storageRef
        .child(data.image)
        .getDownloadURL()
        .then((url) => {
          if (isMountedRef.current) setImage(url);
        })
        .catch((error) => console.log(error));
    }
    return () => (isMountedRef.current = false);
  }, [data, storage]);

  return image && <Avatar src={image} size={68} />;
};

const Map = ({ data, loadMaps }) => {
  const history = useHistory();

  const authenticationContext = React.useContext(AuthenticationContext);
  const firebaseContext = React.useContext(FirebaseContext);

  const { db } = firebaseContext;

  const handleRemove = () => {
    db.collection("maps")
      .doc(data.id)
      .set({ isDeleted: true }, { merge: true })
      .then(() => loadMaps());
  };

  return (
    <Card
      actions={[
        <EyeOutlined
          key="view"
          onClick={() => history.push(`/map/${data.permalink}`)}
        />,
        <Embed
          data={data}
          button={<CodeOutlined key="view" />}
          callback={loadMaps}
        />,
        <MapForm
          data={data}
          button={<EditOutlined key="edit" />}
          callback={loadMaps}
          disabled={!authenticationContext.checkPermission("maps.edit")}
        />,
        <Popconfirm
          title="Tem certeza que deseja remover este mapa?"
          onConfirm={handleRemove}
          okText="Sim"
          cancelText="Não"
          disabled={!authenticationContext.checkPermission("maps.remove")}
        >
          <DeleteOutlined key="delete" style={{ color: "red" }} />
        </Popconfirm>,
      ]}
    >
      <Card.Meta
        avatar={<MapAvatar data={data} />}
        title={data.title}
        description={_.truncate(data.description, { length: 50 })}
      />
    </Card>
  );
};

export default function Maps() {
  const [maps, setMaps] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const authenticationContext = React.useContext(AuthenticationContext);
  const firebaseContext = React.useContext(FirebaseContext);

  const { db } = firebaseContext;

  const loadMaps = React.useCallback(() => {
    setLoading(true);
    db.collection("maps")
      .where("isDeleted", "==", false)
      .orderBy("title", "asc")
      .get()
      .then((qs) => {
        const authMaps = authenticationContext.maps;
        let payload = qs.docs;
        if (authMaps.length > 0) {
          payload = payload.filter((doc) =>
            authMaps.length > 0
              ? authenticationContext.maps.includes(doc.id)
              : true
          );
        }
        payload = payload.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMaps(payload);
        setLoading(false);
      });
  }, [db, authenticationContext]);

  React.useEffect(() => {
    loadMaps();
  }, [loadMaps]);

  return (
    <BaseLayout>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography.Title level={3} style={{ marginBottom: 0 }}>
          Mapas
        </Typography.Title>
        {authenticationContext.checkPermission("maps.create") && (
          <MapForm
            button={<Button type="primary">Criar mapa</Button>}
            callback={loadMaps}
          />
        )}
      </div>
      <Divider style={{ margin: "16px 0" }} />
      {loading || maps === null ? (
        <Spin size="large" />
      ) : (
        <Row gutter={[16, 16]}>
          {maps.map((map) => (
            <Col xs={24} sm={6} key={map.id}>
              <Map data={map} loadMaps={loadMaps} />
            </Col>
          ))}
        </Row>
      )}
    </BaseLayout>
  );
}
