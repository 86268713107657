import React from "react";
import FirebaseContext from "./firebase";
import _ from "lodash";

const AuthenticationContext = React.createContext();

function mapUser(user) {
  return {
    uid: user.uid,
    email: user.email || '',
    displayName: user.displayName || '',
  }
}

const AuthenticationProvider = ({ children }) => {
  const [user, setUser] = React.useState(null);
  const [maps, setMaps] = React.useState(null);
  const [permissions, setPermissions] = React.useState(null);
  const [isAuthenticated, setIsAuthenticated] = React.useState(null);

  const firebaseContext = React.useContext(FirebaseContext);

  const { db, firebaseAuth } = firebaseContext;

  const loadUserCollection = React.useCallback((userId) => {
    db.collection("users")
      .doc(userId)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          setMaps(snapshot.data().maps);
          setPermissions(snapshot.data().permissions);
        }
      });
  }, [db]);

  React.useEffect(() => {
    if (user !== null) loadUserCollection(user.uid);
  }, [user, loadUserCollection]);

  firebaseAuth.onAuthStateChanged((state) => {
    if (state && state.uid) {
      if (user === null) setUser(mapUser(state));
      if (isAuthenticated === null) setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  });

  const checkPermission = (permission) => {
    return _.get(permissions, permission, false);
  }

  const refresh = () => {
    loadUserCollection(user.uid);
  };

  const ready = (isAuthenticated === true || isAuthenticated === false);

  const value = {
    user,
    maps,
    permissions,
    ready,
    isAuthenticated,
    checkPermission,
    refresh,
  };

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationContext;

export { AuthenticationProvider };
