import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Tooltip, Button, Modal, Form, Radio, Typography, Popover } from "antd";
import { fromLonLat } from "ol/proj";
import { Helmet } from "react-helmet";
import {
  HomeOutlined,
  SearchOutlined,
  LogoutOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  ColumnWidthOutlined,
  BorderOutlined,
  ArrowsAltOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import OlMap from "ol/Map";
import OlView from "ol/View";
import OlLayerImage from "ol/layer/Image";
import OlLayerTile from "ol/layer/Tile";
import OlTileWMS from "ol/source/TileWMS";
import OlImageWMS from "ol/source/ImageWMS";
import OlFormatGeoJSON from "ol/format/GeoJSON";
import OlSourceOsm from "ol/source/OSM";
import { ScaleLine } from "ol/control";
import WfsSearchInput from "@terrestris/react-geo/dist/Field/WfsSearchInput/WfsSearchInput";
import ZoomButton from "@terrestris/react-geo/dist/Button/ZoomButton/ZoomButton";
import ToggleGroup from "@terrestris/react-geo/dist/Button/ToggleGroup/ToggleGroup";
import MeasureButton from "@terrestris/react-geo/dist/Button/MeasureButton/MeasureButton";
import Loading from "../Loading";
import ClickInfo from "./ClickInfo";
import Print from "./Print";
import Layers from "./Layers";
import AuthenticationContext from "../../contexts/authentication";
import FirebaseContext from "../../contexts/firebase";
import SettingsContext from "../../contexts/settings";
import _ from 'lodash'
import "antd/dist/antd.css";
import "ol/ol.css";
import "./Show.css";

function scaleControl() {
  return new ScaleLine({
    units: "metric",
  });
}

export default function Map() {
  const history = useHistory();
  let { permalink } = useParams();

  const [olMap, setMap] = React.useState();
  const [mapData, setMapData] = React.useState(null);
  const [mapId] = React.useState(`map-${Math.random()}`);
  const [openSearchDialog, setOpenSearchDialog] = React.useState(false);
  const [searchOptions, setSearchOptions] = React.useState(null);
  const [feature, setFeature] = React.useState(null);
  const [searchMetadata, setSearchMetadata] = React.useState([]);
  const [layerGroups, setLayerGroups] = React.useState({});

  const authenticationContext = React.useContext(AuthenticationContext);
  const firebaseContext = React.useContext(FirebaseContext);
  const settingsContext = React.useContext(SettingsContext);

  const { db, firebaseAuth } = firebaseContext;

  React.useEffect(() => {
    if (mapData?.search.length > 0) {
      setSearchOptions({
        featureTypes: [mapData.search[0].layer],
        searchAttributes: { [mapData.search[0].layer]: [mapData.search[0].field] },
      });
    }
  }, [mapData?.search]);

  const handleChangeSearchOption = (search) => {
    setSearchOptions({
      featureTypes: [search.layer],
      searchAttributes: { [search.layer]: [search.field] },
    });
  }

  const getLayer = React.useCallback((l) => {
    if (l.tiled) {
      return new OlLayerTile({
        title: l.name,
        name: l.name,
        visible: l.visible,
        source: new OlTileWMS({
          ratio: 1,
          url: `${settingsContext.data.geoserverUrl}/public/wfs`,
          serverType: "geoserver",
          crossOrigin: "anonymous",
          params: {
            LAYERS: l.layers,
            VERSION: "1.1.1",
            TILED: true,
            exceptions: "application/vnd.ogc.se_inimage",
          },
        }),
      });
    } else {
      return new OlLayerImage({
        title: l.name,
        name: l.name,
        visible: l.visible,
        source: new OlImageWMS({
          ratio: 1,
          url: `${settingsContext.data.geoserverUrl}/public/wfs`,
          serverType: "geoserver",
          crossOrigin: "anonymous",
          params: {
            LAYERS: l.layers,
            VERSION: "1.1.1",
            exceptions: "application/vnd.ogc.se_inimage",
          },
        }),
      })
    }
  }, [settingsContext.data.geoserverUrl]);

  const initializeMap = React.useCallback((data) => {
    const { layers, lat, lon, zoom, showOpenlayers } = data;

    let tiles = layers.map((l) => getLayer(l));

    setLayerGroups(_.groupBy(layers.map(l => ({ ...l, group: l.group || 'Todas' })), 'group'))

    if (showOpenlayers) {
      tiles = [
          new OlLayerTile({
            name: 'OSM',
            source: new OlSourceOsm(),
          }),
          ...tiles
        ]
    }

    setMap(
      new OlMap({
        layers: [ ...tiles ],
        view: new OlView({
          center: fromLonLat([lon, lat]),
          zoom,
        }),
        controls: [scaleControl()],
      })
    );
  }, [getLayer]);

  React.useEffect(() => {
    db.collection("maps")
      .where("permalink", "==", permalink)
      .get()
      .then((qs) => {
        if (!qs.empty) {
          setMapData(qs.docs[0].data());
          initializeMap(qs.docs[0].data());
        }
      });
  }, [db, permalink, initializeMap]);

  React.useEffect(() => {
    if (olMap) olMap.setTarget(mapId);
  }, [olMap, mapId]);

  const onFetchSuccess = (data) => {
    const layer = searchOptions.featureTypes[0];
    const key = searchOptions.searchAttributes[searchOptions.featureTypes[0]][0];
    const format = new OlFormatGeoJSON();
    const feature = data.find((f) => f.properties?.[key].toLowerCase().includes(f.searchTerm.toLowerCase()));
    const layerFinded = mapData.layers.find((f) => f.layers.toLowerCase().includes(layer.toLowerCase()));
    const meta = layerFinded.metadata || [];
    if (feature) {
      setFeature(format.readFeature(feature));
      setSearchMetadata(meta);
    } else {
      setFeature(null);
    }
  };

  const fitFeature = () => {
    olMap
      .getView()
      .fit(feature.getGeometry().getExtent(), olMap.getSize());
  };

  const handleLogout = () => {
    firebaseAuth
      .signOut()
      .then(() => {})
      .catch(() => {});
  };

  if (!olMap) return <Loading />;

  return (
    <div>
      <Helmet>
        <title>{mapData.title}</title>
      </Helmet>
      {mapData.search.length > 0 && openSearchDialog && (
        <Modal
          title="Busca"
          centered
          visible={openSearchDialog}
          onOk={() => {}}
          onCancel={() => {
            setOpenSearchDialog(false);
            setFeature(null);
          }}
          width={600}
          footer={null}
        >
          <Form
            initialValues={{ kind: mapData.search[0].field }}
          >
            <Form.Item name="kind" label="Pesquisar por">
              <Radio.Group>
                {mapData.search.map(s => (
                  <Radio
                    value={s.field}
                    onClick={() => handleChangeSearchOption(s)}
                    key={s.field}
                  >
                    {s.label}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </Form>
          <WfsSearchInput
            placeholder="Digite o nome do distrito"
            baseUrl={`${settingsContext.data.geoserverUrl}/public/wfs`}
            featureTypes={searchOptions.featureTypes}
            searchAttributes={searchOptions.searchAttributes}
            map={olMap}
            onFetchSuccess={onFetchSuccess}
            onClearClick={() => setFeature(null)}
            minChars={1}
          />
          {feature && (
            <div style={{ marginTop: 24 }}>
              <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
                <Typography.Title level={4} style={{ marginBottom: 0 }}>
                  Informações:
                </Typography.Title> 
                <Button
                  shape="circle"
                  icon={<ArrowsAltOutlined />}
                  onClick={fitFeature}
                  size="small"
                  style={{ marginLeft: 8 }}
                />
              </div>
              {searchMetadata.map((m, index) => (
                <p key={index}><b>{m.label}:</b> {feature?.values_[m.key] || ""}</p>
              ))}
            </div>
          )}
        </Modal>
      )}
      <div style={{ position: "absolute", top: 20, left: 10, zIndex: 3 }}>
        <ZoomButton
          map={olMap}
          type="primary"
          shape="circle"
          icon={<ZoomInOutlined />}
        />
      </div>
      <div style={{ position: "absolute", top: 20, left: 50, zIndex: 3 }}>
        <ZoomButton
          map={olMap}
          delta={-1}
          type="primary"
          shape="circle"
          icon={<ZoomOutOutlined />}
        />
      </div>
      <div style={{ position: "absolute", top: 60, left: 10, zIndex: 3 }}>
        <Tooltip title="Pesquisar" placement="right">
          <Button
            type="primary"
            shape="circle"
            icon={<SearchOutlined />}
            disabled={mapData.search.length === 0}
            onClick={() => setOpenSearchDialog(true)}
          />
        </Tooltip>
      </div>
      <div style={{ position: "absolute", top: 100, left: 10, zIndex: 3 }}>
        <ToggleGroup>
          <MeasureButton
            map={olMap}
            name="line"
            measureType="line"
            type="primary"
            shape="circle"
            icon={<ColumnWidthOutlined />}
            pressedIcon={<ColumnWidthOutlined />}
            tooltip="Medir distância"
            tooltipPlacement="right"
            tooltipProps={{ mouseEnterDelay: 0.1 }}
            continueLineMsg="Clique para desenhar a linha"
            style={{ marginBottom: 8 }}
          />
          <MeasureButton
            map={olMap}
            name="poly"
            measureType="polygon"
            type="primary"
            shape="circle"
            icon={<BorderOutlined />}
            pressed={false}
            pressedIcon={<BorderOutlined />}
            tooltip="Medir área"
            tooltipPlacement="right"
            tooltipProps={{ mouseEnterDelay: 0.1 }}
            continuePolygonMsg="Clique para desenhar o polígono"
          />
        </ToggleGroup>
      </div>
      <div style={{ position: "absolute", top: 220, left: 10, zIndex: 3 }}>
        <Popover
          placement="right"
          title="Camadas"
          content={() => <Layers map={olMap} groups={layerGroups} />}
          trigger="click"
        >
          <Button
            type="primary"
            shape="circle"
            icon={<AppstoreOutlined />}
            onClick={() => {}}
          />
        </Popover>
      </div>
      <Print map={olMap} />
      {authenticationContext.isAuthenticated && (
        <div style={{ position: "absolute", top: 20, right: 10, zIndex: 3 }}>
          <Tooltip title="Sair" placement="left">
            <Button
              type="primary"
              shape="circle"
              icon={<LogoutOutlined />}
              onClick={handleLogout}
              danger
            />
          </Tooltip>
        </div>
      )}
      {authenticationContext.isAuthenticated && (
        <div style={{ position: "absolute", top: 60, right: 10, zIndex: 3 }}>
          <Tooltip title="Tela principal" placement="left">
            <Button
              type="primary"
              shape="circle"
              icon={<HomeOutlined />}
              onClick={() => history.push("/maps")}
            />
          </Tooltip>
        </div>
      )}
      <div
        id={mapId}
        style={{ position: "fixed", width: "100%", height: "100%" }}
      />
      <ClickInfo olMap={olMap} mapData={mapData} />
      <div style={{ position: 'absolute', right: 24, bottom: 24 }}>
        <img src={settingsContext.data.logo} width={120} alt="Tecnogis" />
      </div>
    </div>
  );
}
