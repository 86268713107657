/* eslint-disable no-template-curly-in-string */
import React from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Switch,
  Collapse,
  Transfer,
  message,
} from "antd";
import AuthenticationContext from "../../contexts/authentication";
import FirebaseContext from "../../contexts/firebase";
import axios from "axios";

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 15 },
};

const validateMessages = {
  required: "'${label}' é obrigatório!",
};

const initialValues = {
  displayName: "",
  email: "",
  password: "",
  passwordConfirm: "",
  permissions: {
    maps: {
      list: false,
      create: false,
      edit: false,
      remove: false,
    },
    users: {
      list: false,
      create: false,
      edit: false,
      disable: false,
    },
    map: {
      ownerInformation: false,
    },
  },
};

export default function AddDialog({ data, button, callback = () => {} }) {
  const [form] = Form.useForm();

  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [maps, setMaps] = React.useState([]);
  const [targetKeys, setTargetKeys] = React.useState([]);
  const [selectedKeys, setSelectedKeys] = React.useState([]);

  const isMountedRef = React.useRef(null);

  const authenticationContext = React.useContext(AuthenticationContext);
  const firebaseContext = React.useContext(FirebaseContext);

  const { db, firebaseAuth } = firebaseContext;

  const loadMaps = React.useCallback(() => {
    db.collection("maps")
      .where("isDeleted", "==", false)
      .orderBy("title", "asc")
      .get()
      .then((qs) => {
        if (isMountedRef.current)
          setMaps(qs.docs.map((doc) => ({ key: doc.id, ...doc.data() })));
        setLoading(false);
      });
  }, [db]);

  React.useEffect(() => {
    isMountedRef.current = true;
    loadMaps();
    return () => (isMountedRef.current = false);
  }, [loadMaps]);

  React.useEffect(() => {
    if (data) {
      db.collection("users")
        .doc(data.uid)
        .get()
        .then((snapshot) => {
          if (snapshot.exists) {
            setTargetKeys(snapshot.data().maps);
            form.setFieldsValue({
              ...data,
              permissions: snapshot.data().permissions,
            });
          } else {
            form.setFieldsValue({ ...data });
          }
        });
    }
  }, [db, data, form]);

  const saveUser = (userId, permissions, maps) => {
    db.collection("users")
      .doc(userId)
      .set({ permissions, maps }, { merge: true });
  };

  const onFinish = async (values) => {
    setLoading(true);

    const token = await firebaseAuth.currentUser.getIdToken();

    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    const { displayName, email, password } = values;

    const payload = {
      displayName,
      email,
      password,
    };

    if (data) {
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/api/users/${data.uid}`,
          payload,
          config
        )
        .then((res) => {
          saveUser(res.data.uid, values.permissions, targetKeys);
          setLoading(false);
          setVisible(false);
          authenticationContext.refresh();
          form.setFieldsValue(data);
          message.success("Usuário atualizado com sucesso.");
          callback();
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/users`, payload, config)
        .then((res) => {
          saveUser(res.data.uid, values.permissions, targetKeys);
          setLoading(false);
          setVisible(false);
          authenticationContext.refresh();
          form.setFieldsValue(data);
          message.success("Usuário criado com sucesso.");
          callback();
        });
    }
  };

  const handleOk = () => setVisible(false);

  const handleCancel = () => {
    setVisible(false);
    form.setFieldsValue(data);
  };

  return (
    <>
      {React.cloneElement(button, { onClick: () => setVisible(true) })}
      <Modal
        title={`${data ? "Editar" : "Criar"} usuário`}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        forceRender
      >
        <Form
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          validateMessages={validateMessages}
          initialValues={initialValues}
        >
          <Form.Item
            {...layout}
            name="displayName"
            label="Nome"
            rules={[{ required: true }]}
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item
            {...layout}
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "O e-mail não é válido!",
              },
              {
                required: true,
              },
            ]}
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item {...layout} name="password" label="Senha" hasFeedback>
            <Input.Password autoComplete="new-password" disabled={loading} />
          </Form.Item>
          <Form.Item
            {...layout}
            name="passwordConfirm"
            label="Confirmar senha"
            dependencies={["password"]}
            hasFeedback
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "As duas senhas que você digitou não correspondem!"
                  );
                },
              }),
            ]}
          >
            <Input.Password disabled={loading} />
          </Form.Item>
          <Form.Item label="Permissões" />
          <Form.Item>
            <Collapse>
              <Collapse.Panel header="Mapas" key="1" forceRender>
                <Form.Item
                  name={["permissions", "maps", "list"]}
                  label="Visualizar mapas"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
                <Form.Item
                  name={["permissions", "maps", "create"]}
                  label="Criar mapas"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
                <Form.Item
                  name={["permissions", "maps", "edit"]}
                  label="Editar mapas"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
                <Form.Item
                  name={["permissions", "maps", "remove"]}
                  label="Remover mapas"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Collapse.Panel>
              <Collapse.Panel header="Usuários" key="2" forceRender>
                <Form.Item
                  name={["permissions", "users", "list"]}
                  label="Visualizar usuários"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
                <Form.Item
                  name={["permissions", "users", "create"]}
                  label="Criar usuário"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
                <Form.Item
                  name={["permissions", "users", "edit"]}
                  label="Editar usuário"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
                <Form.Item
                  name={["permissions", "users", "disable"]}
                  label="Desativar usuário"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Collapse.Panel>
              <Collapse.Panel header="Outros" key="3" forceRender>
                <Form.Item
                  name={["permissions", "map", "ownerInformation"]}
                  label="Mapa / Informações do proprietário"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Collapse.Panel>
            </Collapse>
          </Form.Item>
          <Form.Item label="Mapas" />
          <Form.Item>
            <Transfer
              dataSource={maps}
              titles={["disponíveis", "selecionados"]}
              locale={{
                itemUnit: "item",
                itemsUnit: "itens",
                notFoundContent: "Lista vazia",
              }}
              targetKeys={targetKeys}
              selectedKeys={selectedKeys}
              onChange={(nextTargetKeys) => {
                setTargetKeys(nextTargetKeys);
              }}
              onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => {
                setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
              }}
              render={(item) => item.title}
              listStyle={{
                width: 250,
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Enviar
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
