import React from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Card,
} from "antd";
import {
  PlusOutlined,
  DatabaseOutlined,
  MinusCircleFilled,
} from "@ant-design/icons";

export default function MapDialog({ field }) {
  const [visible, setVisible] = React.useState(false);

  const handleOk = () => {};

  return (
    <>
      <Button
        type="default"
        size="small"
        icon={<DatabaseOutlined />}
        onClick={() => setVisible(!visible)}
      >
        Metadados
      </Button>
      <Modal
        title="Metadados"
        visible={visible}
        onOk={handleOk}
        onCancel={() => setVisible(false)}
        footer={null}
        width={600}
        forceRender
      >
        <Form.List name={[field.name, "metadata"]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Card
                  key={`item-${Math.random()}`}
                  style={{ marginBottom: 24 }}
                >
                  <Form.Item
                    name={[field.name, "label"]}
                    fieldKey={[field.fieldKey, "label"]}
                    rules={[{ required: true, message: "Rótulo é obrigatório!" }]}
                  >
                    <Input placeholder="Rótulo" />
                  </Form.Item>
                  <Form.Item
                    name={[field.name, "key"]}
                    fieldKey={[field.fieldKey, "key"]}
                    rules={[{ required: true, message: "Chave é obrigatória!" }]}
                    style={{ marginBottom: 0 }}
                  >
                    <Input placeholder="Chave" />
                  </Form.Item>
                  <MinusCircleFilled
                    onClick={() => remove(field.name)}
                    style={{
                      position: 'absolute',
                      top: -5,
                      right: -7,
                      backgroundColor: 'white'
                    }}
                  />
                </Card>
              ))}
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="dashed"
                  onClick={() => add({ label: "", key: "" })}
                  icon={<PlusOutlined />}
                  block
                >
                  Adicionar metadado
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Modal>
    </>
  )
};
