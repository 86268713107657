/* eslint-disable no-template-curly-in-string */
import React from "react";
import {
  Modal,
  Button,
  Form,
  Input,
} from "antd";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const validateMessages = {
  required: "'${label}' é obrigatório!",
};

const initialValues = {
  geolink: ""
};

export default function MapDialog({
  data,
  button,
  disabled,
}) {
  const [form] = Form.useForm();
  const [visible, setVisible] = React.useState(false);

  const onFinish = async (values) => {
    window.open(`/embed/${data.permalink}/${values.geolink}/800/600`, "_blank");
  };

  const handleOk = () => setVisible(false);

  const handleCancel = () => setVisible(false);

  return (
    <>
      {React.cloneElement(button, {
        onClick: () => {
          if (!disabled) setVisible(true);
        },
      })}
      {visible && (
        <Modal
          title="Gerar embed"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          width={600}
          forceRender
        >
          <Form
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            validateMessages={validateMessages}
            initialValues={initialValues}
          >
            <Form.Item
              {...layout}
              name="geolink"
              label="Geolink"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Gerar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
}
