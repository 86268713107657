import React from "react";
import { Modal, Spin, } from "antd";
import OlTileWMS from "ol/source/TileWMS";
import axios from "axios";
import SettingsContext from "../../contexts/settings";

export default function ClickInfo({ olMap, mapData }) {
  const [openInfoDialog, setOpenInfoDialog] = React.useState(false);
  const [url, setUrl] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState(null);
  const [metadata, setMetadata] = React.useState({});
  const [selectedMetadata, setSelectedMetadata] = React.useState();

  const settingsContext = React.useContext(SettingsContext);

  // React.useEffect(() => {
  //   if (geolink !== null) {
  //     setOpenInfoDialog(true);
  //     axios
  //       .post("https://iptu-smm.prefeitura.info/api/Imovel/get_imovel", { geolink })
  //       .then(res => setData(res.data[0] || {}))
  //       .finally(() => setLoading(false));

  //   }
  // }, [geolink]);

  React.useEffect(() => {
    const search = mapData.layers.some(layer => layer.search);
    if (search) {
      const layersMetadata = mapData.layers
        .filter(l => l.search)
        .reduce((result, item) => {
          result[item.layers] = item.metadata;
          return result;
        }, {});
      setMetadata(layersMetadata);
    }
  }, [mapData]);

  React.useEffect(() => {
    async function loadFeatures() {
      const result = await axios.get(url);
      if (result.data.features.length) {
        setData(result.data.features[0]?.properties);
        setSelectedMetadata(`public:${result.data.features[0]?.id.split('.')[0]}`);
        setOpenInfoDialog(true);
      };
      setLoading(false);
    }
    if (url !== null) loadFeatures();
  }, [url]);

  if (olMap) {
    olMap.on("singleclick", async function(evt) {
      if (!mapData.layers.some(layer => layer.search)) return false;

      const layers = mapData.layers.filter(l => l.search).map(l => l.layers).join(',');

      const wmsSource = new OlTileWMS({
        url: `${settingsContext.data.geoserverUrl}/public/wms`,
        serverType: "geoserver",
        params: {
          LAYERS: layers,
          TILED: true,
        },
      });

      if (evt.map.overlays_.array_.length === 0) {
        const url = wmsSource.getFeatureInfoUrl(
          evt.coordinate,
          olMap.getView().getResolution(),
          "EPSG:3857",
          { "INFO_FORMAT": "application/json" }
        );
        setUrl(url);
      }
    });
  }

  if (data === null) return "";

  return (
    <Modal
      title="Informações"
      visible={openInfoDialog}
      onCancel={() => {
        setOpenInfoDialog(false);
        setUrl(null);
        setData(null);
      }}
      footer={null}
    >
      <Spin tip="Carregando..." spinning={loading}>
        {selectedMetadata && (
          <>
            <div>
              {Object.values(metadata[selectedMetadata]).map((m, index) => (
                <p key={index}><b>{m.label}:</b> {data[m.key]}</p>
              ))}
            </div>
            {metadata[selectedMetadata].length === 0 && (
              <p>Não há informações disponíveis.</p>
            )}
          </>
        )}
      </Spin>
    </Modal>
  );
};