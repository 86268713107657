import React from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import AuthenticationContext from "../contexts/authentication";
import SettingsContext from "../contexts/settings";
import FirebaseContext from "../contexts/firebase";
import Loading from "./Loading";
import "antd/dist/antd.css";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  wrapper: {
    minWidth: 300,
  },
  logo: {
    textAlign: "center",
  },
};

const firebaseErrors = {
  "auth/user-not-found": "Usuário não encontrado.",
  "auth/invalid-email": "O endereço de e-mail é inválido.",
  "auth/wrong-password": "A senha é inválida ou o usuário não possui uma senha.",
  "auth/user-disabled": "Seu usuário foi desabilitado. Entre em contato com o administrador para mais informações."
};

export default function Login() {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  
  const authenticationContext = React.useContext(AuthenticationContext);
  const settingsContext = React.useContext(SettingsContext);
  const firebaseContext = React.useContext(FirebaseContext);

  const { firebaseAuth } = firebaseContext;

  firebaseAuth.onAuthStateChanged((state) => {
    if (state && state.uid) history.push("/");
  });

  if (!authenticationContext.ready) return <Loading />;
  if (settingsContext.data === null) return <Loading />;

  const onFinish = (values) => {
    setLoading(true);
    firebaseAuth
      .signInWithEmailAndPassword(values.email, values.password)
      .then(() => {
        setLoading(false);
        console.log("Logged!");
        history.push("/");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.code);
        message.warning(firebaseErrors[error.code]);
      });
  };

  return (
    <div style={styles.container}>
      <div style={styles.wrapper}>
        <div style={styles.logo}>
          <img
            src={settingsContext.data.logo}
            alt={settingsContext.data.title}
          />
        </div>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ email: "", password: "", remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Por favor insira seu e-mail!" },
            ]}
          >
            <Input
              prefix={<MailOutlined className="site-form-item-icon" />}
              placeholder="E-mail"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Por favor insira sua senha!" }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Senha"
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Mantenha-me logado</Checkbox>
            </Form.Item>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={loading}
            >
              Logar
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
