import React from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { PrinterOutlined } from "@ant-design/icons";
import { Tooltip, Button } from "antd";

export default function MapPrint({ map, d }) {
  const handlePrint = () => {
    const format = "a4";
    const resolution = 72;
    const dim = [297, 210];
    const width = Math.round((dim[0] * resolution) / 25.4);
    const height = Math.round((dim[1] * resolution) / 25.4);
    const size = map.getSize();
    const viewResolution = map.getView().getResolution();

    map.once("rendercomplete", function () {
      html2canvas(document.querySelectorAll(".ol-layer canvas")[0]).then(function(canvas) {
        const pdf = new jsPDF("landscape", undefined, format);
        pdf.addImage(
          canvas,
          "PNG",
          0,
          0,
          dim[0],
          dim[1]
        );
        pdf.save("map.pdf");
      });

      map.setSize(size);
      map.getView().setResolution(viewResolution);
    });

    const printSize = [width, height];
    map.setSize(printSize);
    const scaling = Math.min(width / size[0], height / size[1]);
    map.getView().setResolution(viewResolution / scaling);
  }
  
  return (
    <div style={{ position: "absolute", top: 180, left: 10, zIndex: 3 }}>
      <Tooltip title="Imprimir" placement="right">
        <Button
          type="primary"
          shape="circle"
          icon={<PrinterOutlined />}
          onClick={handlePrint}
        />
      </Tooltip>
    </div>
  );
}